<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <div style="display: flex; justify-content: space-between">
                  <h6 class="mb-0">Danh sách danh mục</h6>
                  <router-link :to="'/campaign/category/create'">
                    <b-button class="btn btn-light font-weight-bold btn-sm"
                      >Tạo mới
                    </b-button>
                  </router-link>
                </div>
              </template>

              <b-table
                table-class="datatable-table"
                hover
                tbody-tr-class="datatable-row"
                thead-tr-class="datatable-row"
                thead-class="datatable-head"
                details-td-class="datatable-cell"
                :items="items"
                :fields="fields"
              >
                <template #cell(icon)="data">
                  <div v-if="data.item.icon">
                    <img style="width: 25px" :src="data.item.icon" alt="">
                  </div>
                </template>
                <template #cell(priority)="data">
                  <b-form-input
                      v-model="data.item.priority"
                      type="number"
                      min="0"
                      style="width: 64px;"
                      @change="updatePriority(data.item)"
                  ></b-form-input>
                </template>
                <template #cell(status)="data">
                 <div v-if="data.item.status ==1" class="label label-inline label-light-success">
                   ON
                 </div>
                 <span v-else class="label label-inline label-light-danger">
                   OFF
                 </span>
                </template>
                <template #cell(challenge)="data">
                 <div>{{data.item.challenges.length}}</div>
                </template>

                <template #cell(action)="data">
                  <div style="display: flex">
                    <router-link
                      :to="'/campaign/category/edit/' + data.item.id"
                    >
                      <b-button pill variant="info" size="sm" title="Sửa"><i class="flaticon2-pen"></i> </b-button>
                    </router-link>

                    <b-button
                      @click="destroy(data.item.id)"
                      pill
                      title="Xóa"
                      variant="danger"
                      size="sm"
                      >
                      <i class="flaticon2-trash"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination-nav
                  v-model="currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const CampaignRepository = RepositoryFactory.get("Campaign");

export default {
  name: "List.vue",
  mixins: [Common],
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "id",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "icon",
          label: "Icon",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "name",
          label: "Tên danh mục",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "challenge",
          label: "Số thử thách",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status",
          label: "Trạng thái",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "priority",
          label: "Thứ tự hiển thị",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Tác vụ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách danh mục", route: "/campaign/category" },
    ]);
    this.filter = this.$route.query;
    this.getList();
  },
  methods: {
    async getList(hide = false) {
      try {
        if (!hide) {
          this.$bus.$emit("show-loading", true);
        }
        let response = await CampaignRepository.getListCategory({page:this.$route.query.page});
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          this.items = body.data.data;
          this.paginate.total = body.data.total;
          this.paginate.totalPage = body.data.last_page;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },

    async destroy(id) {
      if (confirm("Bạn có chắc muốn xóa danh mục")) {
        try {
          this.$bus.$emit("show-loading", true);
          let response = await CampaignRepository.deleteCategory(id);
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.getList(true);
          }
        } catch (e) {
          this.$bus.$emit("show-loading", false);
        }
      }
    },
    updatePriority: async function ( item) {
      const updatedData = {
        id: item.id,
        name: item.name,
        icon: item.icon,
        status: item.status,
        priority: item.priority
      };


      try {
        if (updatedData.priority < 0 ) {
          this.notifyAlert("warn", "Không được phép nhập số âm");
          return;
        }
        else if (!Number.isInteger(Number(updatedData.priority))) {
          this.notifyAlert("warn", "Không được phép nhập số thập phân");
          return;
        }
        this.$bus.$emit("show-loading", true);

        let response = await CampaignRepository.updateCategory(item.id, updatedData);
        this.$bus.$emit("show-loading", false);

        if (response.data.error_code === 1) {
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        } else {
          this.$notify({
            group: "foo",
            type: "success",
            title: response.data.message,
          });
          window.location = "/#/campaign/category";
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
        this.$notify({
          group: "foo",
          type: "error",
          title: "Đã xảy ra lỗi khi cập nhật",
        });
      }
    },
  },
  watch: {
    $route: "getList",
    currentPage() {
      //this.getList();
    },
  },
};
</script>

<style scoped></style>
